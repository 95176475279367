import React from 'react'
import styles from './editor.module.scss'
import cn from 'classnames'

export const HtmlContent = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  if (typeof children === 'string')
    return (
      <div
        dangerouslySetInnerHTML={{ __html: children }}
        className={cn(className, styles.renderer)}
      />
    )

  return <div className={cn(className, styles.renderer)}>{children}</div>
}
